import CommentReplyForm from "./CommentReplyForm";
import React, { useState } from 'react';
import {useLikePostMutation, useDislikePostMutation} from "../../../redux/services/newsfeedService";
import {useSub} from "../../../helpers/usePubSub";

const CommentActions = ({postId, isLiked, likesCount, commentsCount}) => {
    const [visibleReplyForm, setVisibleReplyForm] = useState(false);
    const [localIsLiked, setLocalIsLiked] = useState(isLiked);
    const [localLikesCount, setLocalLikesCount] = useState(likesCount);

    const [likePost] = useLikePostMutation();
    const [dislikePost] = useDislikePostMutation();

    const toggleReplyForm = () => {
        setVisibleReplyForm(!visibleReplyForm);
    };

    const renderLikes = () => {
        const text = localIsLiked ? 'Liked' : 'Like';
        if (localLikesCount > 0) {
            return `${text} (${localLikesCount})`
        }

        return text;
    }

    useSub('post_liked', (post) => {
        if (post.id === postId) {
            setLocalLikesCount(post.likesCount);
        }
    });


    const toggleLike = () => {
        if (localIsLiked) {
            dislikePost(postId);
            setLocalLikesCount(localLikesCount - 1)
        } else {
            likePost(postId);
            setLocalLikesCount(localLikesCount + 1)
        }

        setLocalIsLiked(!localIsLiked);
    }

    return (
        <>
            <div className="text-muted mt-1" style={{cursor: 'pointer'}}>
                <span className={`${localIsLiked ? 'font-weight-bold' : ''}`} onClick={() => toggleLike()}>{renderLikes()}</span> ·
                <span className="ms-1" onClick={() => toggleReplyForm()}>Reply</span>
            </div>
            {visibleReplyForm && (
                <CommentReplyForm postId={postId}/>
            )}
        </>
    )
}

export default CommentActions
