import { useLocation } from 'react-router'
import usePriceWebSocket from './usePriceWebSocket'

export const getAccessTokenfromLocalStorage = () => localStorage.getItem('accessToken') ?? null

export const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const formattedDate = date.toLocaleDateString(undefined, options)
    const formattedTime = date.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    })
    return `${formattedDate}, ${formattedTime}`
}

export const HeaderText = () => {
    const location = useLocation()

    const getHeaderText = () => {
        switch (location.pathname) {
            case '/staking':
                return 'Staking'
            case '/auto-trading':
                return 'Trading BOT'
            case '/signals':
                return 'Signals'
            case '/copy-trading':
                return 'Copy Trading'
            case '/affiliate':
                return 'Referral Program'
            case '/exchange':
                return 'Spot Trading'
            case '/long-term':
                return 'Future'
            case '/security-page':
                return 'Settings'
            case '/reward-center':
                return 'Rewards Center'
            case '/newsfeed':
                return 'News Feed'
            default:
                return ''
        }
    }

    return <h3>{getHeaderText()}</h3>
}

export const renderRiskDots = (riskLevel) => {
    let dotClass = ''

    if (riskLevel === 0) {
        dotClass = 'dot-green'
    } else if (riskLevel === 1) {
        dotClass = 'dot-green'
    } else if (riskLevel === 2) {
        dotClass = 'dot-yellow'
    } else if (riskLevel === 3) {
        dotClass = 'dot-red'
    }

    const dots = []

    for (let i = 0; i < 4; i++) {
        if (riskLevel === 0 && i === 0) {
            dots.push(<div key={i} className={`dot ${dotClass} mx-1 my-1`}></div>)
        } else if (riskLevel === 1 && i <= 1) {
            dots.push(<div key={i} className={`dot ${dotClass} mx-1 my-1`}></div>)
        } else if (riskLevel === 2 && i <= 2) {
            dots.push(<div key={i} className={`dot ${dotClass} mx-1 my-1`}></div>)
        } else if (riskLevel === 3 && i <= 3) {
            dots.push(<div key={i} className={`dot ${dotClass} mx-1 my-1`}></div>)
        } else {
            dots.push(<div key={i} className='dot dot-gray mx-1 my-1'></div>)
        }
    }

    return dots
}

export const getRiskLevelText = (riskLevel) => {
    switch (riskLevel) {
        case 0:
            return 'Low risk'
        case 1:
            return 'Medium risk'
        case 2:
            return 'High risk'
        case 3:
            return 'Highest risk'
        default:
            return ''
    }
}

export const PriceComponent = (props) => {
    const { getPrices } = usePriceWebSocket()
    props.getPricesData(getPrices)
    return null
}
