import React, {useEffect, useState} from 'react'
import {Form, Modal} from 'react-bootstrap'
import * as yup from 'yup'

import {useForm, Controller} from 'react-hook-form'

import {yupResolver} from '@hookform/resolvers/yup'
import LimitAmountSwitch from "./LimitAmountSwitch";
import {isStopLossPriceInvalid, isTakeProfitPriceInvalid} from "../helpers/StopLoss";
import {useGetCFDOpenPositionCalculationQuery, useUpdatePositionMutation} from "../../../redux/services/cfdService";
import {useGetUserFiatBalanceQuery} from "../../../redux/services/userService";

const EditOrderModal = (props) => {
    const {
        position,
        isModalOpen,
        setIsModalOpen,
        userEnabledFeatures
    } = props

    // State
    const [stopLossMode, setStopLossMode] = useState(position.stop_loss_price === null ? 'Amount' : 'Limit')
    const [takeProfitMode, setTakeProfitMode] = useState(position.take_profit_price === null ? 'Amount' : 'Limit')
    const [stopLossLongTargetPrice, setStopLossLongTargetPrice] = useState(0)
    const [takeProfitLongTargetPrice, setTakeProfitLongTargetPrice] = useState(null)
    const [stopLossPrice, setStopLossPrice] = useState(position.stop_loss_price)
    const [takeProfitPrice, setTakeProfitPrice] = useState(position.take_profit_price)
    const [takeProfit, setTakeProfit] = useState(position.take_profit)
    const [stopLoss, setStopLoss] = useState(position.stop_loss)
    const [increaseMargin, setIncreaseMargin] = useState(false)
    const [newMargin, setNewMargin] = useState(10)
    const isEditMarginFeatureEnabled = userEnabledFeatures.includes('edit-margin');

    const shouldFetchCalculation = () => {
        return stopLossMode === 'Limit' || takeProfitMode === 'Limit'
    }

    // RTK
    const {currentData: cfdOpenCalculation, refetch: refetchCalculation} = useGetCFDOpenPositionCalculationQuery({
        margin: position.margin,
        asset_symbol: position.asset,
        direction: position.direction,
        leverage: 1
    }, {skip: !shouldFetchCalculation()})

    const [updatePosition] = useUpdatePositionMutation()

    const {currentData: fiatBalance} = useGetUserFiatBalanceQuery()

    // Form
    const defaultValues = {
        stopLoss: position.stop_loss,
        takeProfit: position.take_profit,
        stopLossPrice: null,
        takeProfitPrice: null,
    }

    const FormValidationSchema = yup.object().shape({
        stop_loss_price: yup.number().typeError('Stop loss price must be a number').nullable().transform((value, originalValue) => {
            return originalValue === '' ? null : value;
        }),
        take_profit_price: yup.number().typeError('Take profit price must be a number').nullable().transform((value, originalValue) => {
            return originalValue === '' ? null : value;
        }),
        stop_loss: yup.number().typeError('Stop loss must be a number').nullable(),
        take_profit: yup.number().typeError('Take profit must be a number').nullable(),
        margin: yup.number().typeError('Margin must be a number').nullable().when('increaseMargin', {
            is: true,
            then: yup.number().required('Margin is required when increasing margin').min(0.01, 'Margin must be at least 0.01')
        })
    })

    const {
        control,
        handleSubmit,
        setError,
        formState: {errors},
        setValue,
    } = useForm({defaultValues, resolver: yupResolver(FormValidationSchema)})

    // Form functions
    useEffect(() => {
        if (shouldFetchCalculation()) {
            refetchCalculation();
        }
    }, [stopLossMode, takeProfitMode]);

    useEffect(() => {
        if (cfdOpenCalculation?.data) {
            setStopLossLongTargetPrice(cfdOpenCalculation.data.stopLossTargetPrice);
            setTakeProfitLongTargetPrice(cfdOpenCalculation.data.takeProfitTargetPrice);

            if (isTakeProfitPriceInvalid(takeProfitPrice, cfdOpenCalculation.data.takeProfitTargetPrice, position.direction)) {
                setTakeProfitPrice(parseFloat(cfdOpenCalculation.data.takeProfitTargetPrice).toFixed(2));
            }

            if (isStopLossPriceInvalid(stopLossPrice, cfdOpenCalculation.data.stopLossTargetPrice, position.direction)) {
                setStopLossPrice(parseFloat(cfdOpenCalculation.data.stopLossTargetPrice).toFixed(2));
            }
        }
    }, [cfdOpenCalculation]);


    const stopLossPriceChanged = (e) => {
        setValue('stop_loss_price', String(e.target.value))
        setStopLossPrice(parseFloat(e.target.value))
    }

    const onStopLossPriceInputBlur = () => {
        if (isStopLossPriceInvalid(stopLossPrice, stopLossLongTargetPrice, position.direction)) {
            setStopLossPrice(parseFloat(stopLossLongTargetPrice).toFixed(2));
        }
    }

    const onStopLossChange = (e) => {
        setStopLoss(e.target.value)
    }

    const onStopLossInputBlur = () => {
        let stopLossValue = stopLoss ? stopLoss.replace(/,/g, '.') : null

        if (stopLossValue && stopLossValue[0] !== '-') {
            stopLossValue = '-' + stopLossValue;
        }

        setStopLoss(stopLossValue ? stopLossValue.replace(/,/g, '.') : null)
    }

    const onTakeProfitChange = (e) => {
        setTakeProfit(e.target.value)
    }

    const onTakeProfitInputBlur = () => {
        const takeProfitValue = takeProfit ? takeProfit.replace(/,/g, '.') : null
        setTakeProfit(takeProfitValue)
    }

    const takeProfitPriceChanged = (e) => {
        if (e.target.value === '') {
            setTakeProfitPrice(null)
            return;
        }
        setTakeProfitPrice(parseFloat(e.target.value))
    }

    const onTakeProfitPriceInputBlur = () => {
        if (isTakeProfitPriceInvalid(takeProfitPrice, takeProfitLongTargetPrice, position.direction)) {
            setTakeProfitPrice(parseFloat(takeProfitLongTargetPrice).toFixed(2));
        }
    }

    // Modal functions
    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const onSubmit = async () => {
        const stopLossData = {
            stop_loss: stopLoss ? Number(stopLoss) : null,
            stop_loss_price: stopLossPrice ? Number(stopLossPrice) : null
        }

        if (stopLossMode === 'Limit') {
            stopLossData.stop_loss = null
        } else {
            stopLossData.stop_loss_price = null
        }

        const takeProfitData = {
            take_profit: takeProfit ? Number(takeProfit) : null,
            take_profit_price: takeProfitPrice ? Number(takeProfitPrice) : null
        }

        if (takeProfitMode === 'Limit') {
            takeProfitData.take_profit = null
        } else {
            takeProfitData.take_profit_price = null
        }

        const marginData = increaseMargin ? {margin_to_add: newMargin} : {}

        try {
            const response = await updatePosition({
                id: position.id,
                data: {
                    ...stopLossData,
                    ...takeProfitData,
                    ...marginData
                }
            })

            if (response?.data?.errors) {
                Object.keys(
                    response.data.errors,
                ).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: response.data.errors[key][0]
                    })
                })
                return;
            }

            if (response?.error?.data?.message) {
                setError('apiError', {
                    type: 'manual',
                    message: response.error.data.message
                })
                return;
            }

            handleCloseModal();
        } catch (err) {

            if (err.response?.data?.errors) {
                Object.keys(
                    err.response.data.errors,
                ).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: err.response.data.errors[key][0]
                    })
                })
            }

            if (err.response?.data?.message) {
                setError('apiError', {
                    type: 'manual',
                    message: err.response.data.message
                })
            }
        }
    }

    const newMarginValue = () => {
        if (!isNaN(parseFloat(newMargin))) {
            return (parseFloat(position.margin) + parseFloat(newMargin)).toFixed(2);
        }

        return '-'
    }

    const changeNewMargin = (value) => {
        if (parseFloat(value) < 0.01) {
            setNewMargin(0.01)
        }
    }

    const renderIncreaseMargin = () => {
        if (isEditMarginFeatureEnabled) {
            return (
                <div className="mb-3">
                    <Form.Check
                        type="checkbox"
                        id="increaseMargin"
                        label={<span>Increase margin of the position</span>}
                        checked={increaseMargin}
                        onChange={(e) => setIncreaseMargin(e.target.checked)}
                    />
                    {increaseMargin && (
                        <div className="input-group mt-2">
                            <Form.Control
                                type="number"
                                placeholder="New Margin"
                                value={newMargin}
                                onChange={(e) => setNewMargin(e.target.value)}
                                onBlur={() => changeNewMargin(newMargin)}
                                isInvalid={errors.margin}
                                min={0.01}
                                step={0.01}
                            />
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div className="text-muted">
                                    Margin will be increased to {newMarginValue()} {position.quote_currency}
                                </div>
                                <span className='text-muted'>
                                                    Cash Available:
                                    {fiatBalance?.data?.balance ? (
                                        <>
                                                            <span
                                                                onClick={() => setNewMargin(parseFloat(fiatBalance?.data?.balance).toFixed(2))}
                                                                className='text-secondary a-link'>
                                                                {' '}{parseFloat(fiatBalance?.data?.balance).toFixed(2)}{' '}
                                                                {fiatBalance.data.currency}
                                                            </span>
                                        </>
                                    ) : (
                                        <>{' '}0 {fiatBalance?.data?.currency || 'USD'}</>
                                    )}
                                                </span>
                            </div>
                            {errors?.margin && (
                                <Form.Control.Feedback
                                    type='invalid'>{errors.margin.message}</Form.Control.Feedback>
                            )}
                        </div>
                    )}
                </div>
            );
        }

        return (<></>);
    }

    return (
        <Modal
            className='modal fade'
            id='icoModal'
            show={isModalOpen}
            onHide={() => {
                handleCloseModal()
            }}
            fullscreen='sm-down'>
            <Modal.Dialog className=''>
                <div className='modal-content'>
                    <Modal.Header className='modal-header' closeButton>
                        <h5 className='modal-title'>Edit position</h5>
                    </Modal.Header>
                    <Modal.Body className='modal-body custom_setting'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <label htmlFor="stopLossMode" className="form-label">Stop Loss</label>
                                        <LimitAmountSwitch id="stopLossMode"
                                                           onSwitch={value => setStopLossMode(value)}
                                                           initialValue={stopLossMode}/>
                                    </div>
                                    {stopLossMode === 'Amount' && (
                                        <div>
                                            <div className="input-group ">
                                                <Controller
                                                    control={control}
                                                    name='stop_loss'
                                                    render={({field: {onChange, value}}) => (
                                                        <Form.Control
                                                            onChange={onStopLossChange}
                                                            onBlur={() => onStopLossInputBlur()}
                                                            value={stopLoss}
                                                            isInvalid={errors.stop_loss}
                                                            placeholder='Stop loss'
                                                            type='text'
                                                        />
                                                    )}
                                                />
                                                <span
                                                    className="input-group-text">{position.quote_currency}</span>
                                                {errors?.stop_loss && (
                                                    <Form.Control.Feedback
                                                        type='invalid'>{errors.stop_loss.message}</Form.Control.Feedback>
                                                )}
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                width: '100%'
                                            }}>
                                                <span className="text-muted">Min: <span
                                                    onClick={() => setStopLoss('-0.01')}
                                                    className="text-secondary a-link">-0.01 {position.quote_currency}</span></span>
                                            </div>
                                        </div>
                                    )}
                                    {stopLossMode === 'Limit' && (
                                        <div>
                                            <div className="input-group">
                                                <Controller
                                                    control={control}
                                                    name='stop_loss_price'
                                                    render={({field: {onChange, value}}) => (
                                                        <Form.Control
                                                            onChange={stopLossPriceChanged}
                                                            value={stopLossPrice ? stopLossPrice : null}
                                                            onBlur={() => onStopLossPriceInputBlur()}
                                                            isInvalid={errors.stop_loss_price}
                                                            placeholder='Stop loss price'
                                                            type='number'
                                                        />
                                                    )}
                                                />
                                                <span
                                                    className="input-group-text">{position.quote_currency}</span>
                                                {errors?.stop_loss_price && (
                                                    <Form.Control.Feedback
                                                        type='invalid'>{errors.stop_loss_price.message}</Form.Control.Feedback>
                                                )}
                                            </div>
                                            {stopLossLongTargetPrice ? (
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    width: '100%'
                                                }}>
                                                    <span
                                                        className="text-muted">{position.direction === 'long' ? 'Max' : 'Min'}: <span
                                                        onClick={() => setStopLossPrice(stopLossLongTargetPrice.toFixed(2))}
                                                        className="text-secondary a-link">{stopLossLongTargetPrice.toFixed(2)} {position.quote_currency}</span></span>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <label htmlFor="takeProfitMode" className="form-label">Take
                                            Profit</label>
                                        <LimitAmountSwitch id="takeProfitMode"
                                                           onSwitch={value => setTakeProfitMode(value)}
                                                           initialValue={takeProfitMode}/>
                                    </div>
                                    {takeProfitMode === 'Amount' && (
                                        <div>
                                            <div className="input-group">
                                                <Controller
                                                    control={control}
                                                    name='take_profit'
                                                    render={({field: {onChange, value}}) => (
                                                        <Form.Control
                                                            onChange={onTakeProfitChange}
                                                            onBlur={() => onTakeProfitInputBlur()}
                                                            value={takeProfit}
                                                            isInvalid={errors.take_profit}
                                                            placeholder='Take profit'
                                                            type='text'
                                                        />
                                                    )}
                                                />
                                                <span
                                                    className="input-group-text">{position.quote_currency}</span>
                                                {errors?.take_profit && (
                                                    <Form.Control.Feedback
                                                        type='invalid'>{errors.take_profit.message}</Form.Control.Feedback>
                                                )}
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                width: '100%'
                                            }}>
                                                <span className="text-muted">Min: <span
                                                    onClick={() => setTakeProfit('1')}
                                                    className="text-secondary a-link">1 {position.quote_currency}</span></span>
                                            </div>
                                        </div>
                                    )}
                                    {takeProfitMode === 'Limit' && (
                                        <div>
                                            <div className="input-group">
                                                <Controller
                                                    control={control}
                                                    name='take_profit_price'
                                                    render={({field: {onChange, value}}) => (
                                                        <Form.Control
                                                            onChange={takeProfitPriceChanged}
                                                            onBlur={() => onTakeProfitPriceInputBlur()}
                                                            value={takeProfitPrice ? takeProfitPrice : ''}
                                                            isInvalid={errors.take_profit_price}
                                                            placeholder='Take profit price'
                                                            type='number'
                                                        />
                                                    )}
                                                />
                                                <span
                                                    className="input-group-text">{position.quote_currency}</span>
                                                {errors?.take_profit_price && (
                                                    <Form.Control.Feedback
                                                        type='invalid'>{errors.take_profit_price.message}</Form.Control.Feedback>
                                                )}
                                            </div>
                                            {takeProfitLongTargetPrice && (
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    width: '100%'
                                                }}>
                                                    <span
                                                        className="text-muted">{position.direction === 'long' ? 'Min' : 'Max'}: <span
                                                        onClick={() => setTakeProfitPrice(takeProfitLongTargetPrice.toFixed(2))}
                                                        className="text-secondary a-link">{takeProfitLongTargetPrice.toFixed(2)} {position.quote_currency}</span></span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {renderIncreaseMargin()}
                                <div className='mb-1 mt-3 d-flex justify-content-between'>
                                    <button
                                        type="submit"
                                        className='btn flex-fill py-2 fs-5 px-5 w-100 btn btn-primary'>Save
                                    </button>
                                </div>
                                {errors?.apiError && (
                                    <div className='invalid-feedback mb-3 text-center'
                                         style={{display: 'block'}}>
                                        {errors.apiError.message}
                                    </div>
                                )}
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal.Dialog>
        </Modal>
    )
}

export default EditOrderModal


