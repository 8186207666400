import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import Header from '../component/Comman/Header'
import Dashboard from './Dashboard/Dashboard'
import Exchange from './Exchange/Exchange'
import RewardCenter from './RewardCenter/RewardCenter'

import Staking from './Staking/Staking'
import StaterPage from './Stater Page/StaterPage'
import SecurityPage from './SecurityPage/SecurityPage'
import Affiliate from './Affiliate/Affiliate'
import LongTerm from './CFD.js/LongTerm'

import { Toaster } from 'react-hot-toast'

import { getToken } from '../redux/helpers/utils'

import 'react-tooltip/dist/react-tooltip.css'
import AutoTrading from './AutoTrading/AutoTrading'
import Signals from './Signals/Signals'
import CopyTrading from "./CopyTrading/CopyTrading";
import NewsFeed from "./NewsFeed/NewsFeed";

const MainIndex = () => {
    const token = getToken()
    const PrivateRoute = ({ element }) => {
        if (!token) return <Navigate replace to='/login' />
        return element
    }

    const privateRouting = useRoutes([
        { path: '/', element: <PrivateRoute element={<Dashboard />} /> },
        { path: '/exchange', element: <PrivateRoute element={<Exchange />} /> },
        { path: '/auto-trading', element: <PrivateRoute element={<AutoTrading />} /> },
        { path: '/signals', element: <PrivateRoute element={<Signals />} /> },
        { path: '/copy-trading', element: <PrivateRoute element={<CopyTrading />} /> },
        { path: '/staking', element: <PrivateRoute element={<Staking />} /> },
        { path: '/stater-page', element: <PrivateRoute element={<StaterPage />} /> },
        { path: '/security-page', element: <PrivateRoute element={<SecurityPage />} /> },
        { path: '/affiliate', element: <PrivateRoute element={<Affiliate />} /> },
        { path: '/long-term', element: <PrivateRoute element={<LongTerm />} /> },
        { path: '/reward-center', element: <PrivateRoute element={<RewardCenter />} /> },
        { path: '/newsfeed', element: <PrivateRoute element={<NewsFeed />} /> }
    ])

    return (
        <div className='main px-lg-4 px-md-4'>
            <Header />
            <Toaster />
            <div className='body d-flex py-3 '>{privateRouting}</div>
        </div>
    )
}

export default MainIndex
